/*
    Sales Funnel CSS
    2022-02-22
    Amercan Marketing Inc.
*/

/*
    Fonts
*/

@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/*
    Btn 
*/
.btn-success {

    color: white;
    font-weight: bold;
    border-radius: 4px;
    padding: 4px 8px 4px 8px;
}

.btn-success:hover {

    color: white;
}

/*
    Sales Funnel Wrapper
*/
.sales-funnel-wrapper {

    position: relative;
    width: 100%;
    height: auto;
    min-height: 100vh;
    padding-bottom: 300px;
    background-color: rgb(255, 255, 255);
    font-family: 'Barlow Semi Condensed';
    font-size: 1rem;

    .navbar {
        background-color: GetColor(white);
    }
}

/*
    Sales Exclusive Offer
*/
.sales-exclusive-offer {

    display: flex;
    position: relative;
    width: 100%;
    background-color: GetColor(primary);
    color: GetColor(white);

    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;

    text-transform: uppercase;
    font-size: 1.25rem;

    letter-spacing: 2px;

    align-items: center;
    justify-content: center;

    a {

        margin-left: 20px;
    }
}

/*
    Hook
*/
.hook {

    position: relative;
    text-transform: uppercase;
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;

    .super-header {

        letter-spacing: 1px;
        font-size: 2rem;
    }

    h1 {

        letter-spacing: 2px;
        font-weight: bold;
        font-size: 3rem;
    }

    .visual-flair {

        position: relative;

        img {

            width: 100%;
            max-width: 850px;
        }
    }
}

@media(max-width: $smallPhoneSize) {

    .hook {

        .super-header {

            font-size: 1.6rem;
        }

        h1 {

            font-size: 2rem;
        }
    }
}

/*  
    Claim Offer
*/
.claim-offer {

    position: relative;
    text-align: center;
    text-transform: uppercase;
    margin-top: 30px;
    margin-bottom: 30px;

    a {
        
        border-radius: 6px;
        padding: 15px 60px 15px 60px;
        font-size: 3rem;
        font-weight: 800;
        letter-spacing: 3px;
        
        background-color: GetColor(sales-red);
        color: white;

        text-decoration: none;

        transition: 0.5s ease all;
    }

    a:hover {

        background-color: lighten(GetColor(sales-red), 10%);
    }

    .time-pressure {

        margin-top: 40px;
        margin-bottom: 40px;

        p {
            font-size: 1.5rem;
            margin-bottom: 5px;
        }

        img {

            width: 100%;
            max-width: 500px;
        }
    }
}

@media(max-width: $phoneSize) {

    .claim-offer {

        a {

            padding: 15px 30px 15px 30px;
            font-size: 1.5rem;
        }

        .time-pressure {

            img {

                width: 80%;
            }
        }
    }
}

@media(max-width: $smallPhoneSize) {

    .claim-offer {

        a {

            padding: 15px 20px 15px 20px;
            font-size: 1.3rem;
        }
    }
}

/*
    Logo Strip
*/
.logo-strip {

    position: relative;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 80px;

    .desktop {

        display: block;
    }

    .mobile {

        display: none;
    }

    img {

        width: 100%;
    }
}

@media(max-width: $ipadSize) {

    .logo-strip {

        .desktop {

            display: none;
        }

        .mobile {

            display: block;
        }
    }
}

/*
    Trust Builder
*/
.trust-builder {

    position: relative;
    padding-bottom: 70px;
    text-align: center;
    font-weight: 400;

    h2 {

        position: relative;
        margin-top: 30px;
        margin-bottom: 30px;
        letter-spacing: 2px;
        font-weight: bold;
        font-size: 4rem;
        text-transform: uppercase;
    }

    p.text-lines {

        position: relative;
        padding-left: 20%;
        padding-right: 20%;
        font-size: 1.8rem;
        text-align: left;
    }

    p.bullet {

        position: relative;
        padding-left: 20%;
        padding-right: 20%;
        font-size: 1.8rem;
        text-align: left;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    p.bullet::before {
        
        position: absolute;
        top: 5px;
        left: calc(20% - 60px);
        content: "";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
        background-image: url('/images/list_bullet.png'); /* Change the color */
        background-position: 0px, 0px;
        background-size: 35px;

        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 35px; /* Also needed for space (tweak if needed) */
        height: 35px;
        //margin-right: 20px;
    } 

    img {

        width: 100%;
        max-width: 900px;
        margin-bottom: 40px;
    }
}

@media(max-width: $phoneSize) {

    .trust-builder {

        h2 {

            font-size: 2.5rem;
        }

        p.text-lines {

            padding-left: 10px;
            padding-right: 10px;
        }

        p.bullet {

            position: relative;
            padding-left: 13%;
            padding-right: 10%;
            font-size: 1.8rem;
            text-align: left;
            margin-top: 30px;
            margin-bottom: 30px;
        }

        
        p.bullet::before {
            
            position: absolute;
            top: 5px;
            left: calc(10% - 40px);
            content: "";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
            background-image: url('/images/list_bullet.png'); /* Change the color */
            background-position: 0px, 0px;
            background-size: 35px;

            display: inline-block; /* Needed to add space between the bullet and the text */
            width: 35px; /* Also needed for space (tweak if needed) */
            height: 35px;
            //margin-right: 20px;
        } 
    }
}

/*
    Exit Intent Popup
*/
.ex-intent {
    
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.35);
    transform: scale(0);
    transition: 0.4s ease all;

    .ex-intent-content {

        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        max-width: 800px;
        height: auto;
        border-radius: 8px;
        padding: 30px;

        transform: translateX(-50%) translateY(-50%);

        background-color: GetColor(primary);
        color: GetColor(white) !important;

        text-align: center;

        .btn-close {

            position: absolute;
            top: 10px;
            right: 10px;
            background-color: GetColor(sales-red);
            color: GetColor(primary);

            transition: 0.5s ease all;
            font-size: 20px;
        }

        .header {

            text-transform: uppercase;
            font-weight: 700;
            font-size: 3.5rem;
        }

        .text {
            
            font-size: 2rem;
        }

        a {

            font-size: 3.75rem;
            text-transform: uppercase;
            padding: 15px 60px 15px 60px;
        }
    }
}

.ex-intent.visible {

    transform: scale(1);
}

@media(max-width: $phoneSize) {

    .ex-intent {

        .ex-intent-content {

            .header {

                font-size: 2rem;
            }

            .text {

                font-size: 1.5rem;
            }

            a {

                font-size: 2rem;
            }
        }
    }
}

/*
    Capture
*/
.capture {

    position: relative;
    width: 100%;
    height: auto;
    min-height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;

    background-color: GetColor(white);

    .rocket {

        position: absolute;
        z-index: 0;

        width: 50%;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }

    .capture-content {

        position: relative;
        width: auto;
        height: auto;
        z-index: 1;

        .header {

            text-transform: uppercase;
            font-size: 2.75rem;
            letter-spacing: 2px;
        }

        .sub-header {

            text-transform: uppercase;
            font-size: 2rem;
            letter-spacing: 1px;
            margin-bottom: 30px;
        }

        .btn-success {

            text-transform: uppercase;
            font-size: 2.5rem;
            padding: 10px 50px 10px 50px;
            margin-top: 20px;
        }

        .input-group {

            font-size: 1.5rem;

            max-width: 500px;

            .input-group-text {

                font-size: 1.5rem;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            .form-control {

                font-size: 1.5rem;
            }
        }
    }
}

@media(max-width: $ipadSize) {

    .capture {

        .rocket {

            width: 80%;
        }
    }
}

@media(max-width: $phoneSize) {

    .capture {

        .rocket {

            width: 100%;
        }

        .btn-success {

            margin-bottom: 200px; 
        }
    }
}

/*
    Thank you
*/
.thank-you {

    position: relative;
    width: 100%;
    height: auto;
    min-height: 100vh;
    
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;

    background-color: GetColor(white);

    .rocket {

        position: absolute;
        z-index: 0;

        width: 50%;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
    
    .thank-you-content {

        position: relative;
        z-index: 1;

        .header {

            text-transform: uppercase;
            font-size: 3rem;
            letter-spacing: 2px;
            font-weight: bold;
        }

        .sub-header {

            text-transform: uppercase;
            font-size: 2.25rem;
            letter-spacing: 1px;
            margin-bottom: 20px;
            font-style: italic;
        }

        .btn-success {

            text-transform: uppercase;
            font-size: 2.75rem;
            padding: 10px 50px 10px 50px;
            margin-top: 40px;
        }

        p {

            font-size: 2rem;
            margin-bottom: 50px;
        }

        .btn-primary {

            font-size: 2rem;
            text-transform: uppercase;
        }
    }
}

@media(max-width: $ipadSize) {

    .thank-you {
        .rocket {
            width: 80%;
        }
    }
}

@media(max-width: $phoneSize) {

    .thank-you {

        .rocket {
            width: 100%;
        }

        .thank-you-content {

            .header {

                font-size: 2rem;
            }

            .sub-header {

                font-size: 1.5rem;
            }

            p {

                font-size: 1.25rem;
            }

            .btn-primary {

                font-size: 2rem;
            }
        }
    }
}