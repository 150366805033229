html,
body {
    position: relative;
    width: 100vw;
    height: auto;
    scroll-behavior: smooth;
    overflow-x: hidden;
    background-color: GetColor(white);

    font-family: Ubuntu;
}

/*
    Wrapper
*/
.wrapper {

    position: relative;
    height: auto;
    min-height: 100vh;
    padding-bottom: 400px;
}

/*
    Btns
*/
.btn {

    border-radius: 10px !important;
}

/*
    Navbar
*/
.navbar {
    z-index: 2;
}

.navbar-brand {
    margin-left: 40px;

    img {
        width: 150px;
    }
}

.navbar-nav {
    
    .nav-item .nav-link {
        font-size: 26px;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-right: 20px;
    }
}

@media(max-width: $ipadSize) {

    .navbar-brand {

        img {

            width: 100px;
        }
    }
}

@media (max-width: $phoneSize) {

    .navbar-brand {

        img {

            width: 70px;
        }
    }
}

/*
    Offer Strip
*/
.offer-strip {

    background-color: GetColor(primary);
    color: GetColor(white);

    padding: 15px;
    text-align: center;
    font-size: 20px;

    .btn.btn-secondary {

        margin-left: 10px;
        padding: 2px 8px 2px 8px;
        text-transform: uppercase;
    }
}

.offers {

    text-align: center;
    text-transform: uppercase;

    margin-bottom: 40px;
    
    .header {

        font-size: 2.75rem;
        letter-spacing: 2px;
        font-weight: bold;
    }

    .sub-header {

        font-size: 2rem;
        letter-spacing: 1px;
        color: lighten(GetColor(black), 20%);
        margin-bottom: 40px;
    }

    .section {

        font-size: 2rem;
        letter-spacing: 1px;
        margin-top: 30px;
        margin-bottom: 10px;
    }

    p {

        text-align: left;
        text-transform: none;
        font-size: 1.25rem;
    }

    .video {

        margin-top: 40px;
        margin-bottom: 50px;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media(max-width: $phoneSize) {

    .offers {

        .header {

            font-size: 2.25rem;
        }

        .sub-header {

            font-size: 1.75rem;
        }

        p {

            font-size: 1.4rem;
        }

        .video {

            width: 90%;
        }
    }
}

/*
    Hero
*/
.hero {

    position: relative;
    width: 100%;
    height: 450px;

    .header {

        width: auto;
        position: absolute;
        font-size: 128px;
        text-transform: uppercase;
        letter-spacing: 5px;
        font-weight: 500;
        line-height: 130px;
        z-index: 1;

        top: 40px;

        .sub {

            font-size: 56px;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-weight: normal;
            line-height: 60px;
        }

        .call-to-action {

            margin-top: 30px;
            text-align: right;
            text-transform: uppercase;
            line-height: 22px;

            a {
                font-size: 22px !important;
                font-weight: 600 !important;
            }
        }
    }

    .rocket {

        position: absolute;
        top: 30%;
        right: 5%;
        width: 50vw;
        max-width: 900px;
        z-index: 0;
    }
}

@media (max-width: $phoneSize) {

    .hero {


        .header {

            font-size: 80px;
            text-transform: uppercase;
            letter-spacing: 5px;
            font-weight: 500;
            line-height: 82px;

            .sub {

                font-size: 35px;
                text-transform: uppercase;
                letter-spacing: 2px;
                font-weight: normal;
                line-height: 38px;
            }

            .call-to-action {

                margin-top: 30px;
                text-align: right;
                text-transform: uppercase;
                line-height: 18px;
    
                a {
                    font-size: 16px !important;
                    font-weight: 600 !important;
                }
            }
        }

        
        .rocket {

            position: absolute;
            bottom: 10%;
            left: 50%;
            width: 75vw;
            transform: translateX(-50%);
            z-index: 0;
        }
    }
}

/*
    Mission
*/
.mission {

    position: relative;
    width: 100%;
    height: auto;
    margin-top: 100px;
    margin-bottom: 100px;

    font-size: 48px;
    font-weight: 500;
    letter-spacing: 3px;
    text-transform: uppercase;
    text-align: center;

    .header {
        margin-bottom: 20px;
    }

    .text {

        font-size: 18px;
        font-weight: normal;
        text-transform: initial;
        letter-spacing: normal;
    }
}

/*
    Features
*/
.features {

    position: relative;
    width: 100%;
    height: auto;
    margin-top: 80px;
    margin-bottom: 80px;

    font-size: 20px;
    text-align: center;

    .icon {
        position: relative;
        width: 60%;
        margin-bottom: 40px;
        z-index: 1;
    }

    .stagger {

        margin-top: 140px;
    }

    .text {

        position: relative;
        z-index: 1;
    }

    .rocket {

        position: absolute;
        bottom: -20%;
        right: 0%;
        width: 55vw;
        z-index: 0;
        max-width: 1000px;
        transform: rotate(-25deg);
    }
}

@media(max-width: $phoneSize) {

    .features {

        .stagger {

            margin-top: 0px;
        }

        .col-md-6 {

            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
}

/*
    Google Listing Example
*/
.google-listing {

    position: relative;
    width: 100%;
    height: auto;
    margin-top: 80px;
    margin-bottom: 100px;

    font-size: 48px;
    font-weight: 500;
    letter-spacing: 3px;
    text-transform: uppercase;
    text-align: center;

    .header {
        margin-bottom: 20px;
    }

    .search-bar {

        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .word-generator {

            position: relative;
            width: 60%;
            height: 47px;
            border-radius: 4px;
            border: none;
            
            background-color: darken(GetColor(white), 10%);

            margin-right: 20px;
            font-size: 22px;
            padding-left: 20px;
        }

        .btn-search {

            line-height: 22px;

            a {
                font-size: 22px !important;
                font-weight: 600 !important;
            }
        }
    }

    .text {

        margin-top: 30px;
        margin-bottom: 30px;
        font-size: 20px;
        text-align: center;
        letter-spacing: normal;
        text-transform: initial;
    }

    .listings {

        margin-top: 60px;
        position: relative;
        width: 100%;
        height: auto;

        .listing {
            
            display: flex;
            flex-direction: row;
            margin-bottom: 40px;

            .picture {

                width: 18%;
                border-radius: 6px;
            }

            .details {


                position: relative;
                display: flex;
                align-items: center;
                width: auto;
                margin-left: 30px;

                font-size: 16px;
                line-height: 28px;
                font-weight: normal;
                letter-spacing: normal;
                text-align: left;
                color: GetColor(grey);

                .name {
                    font-size: 26px;
                    font-weight: 500;
                    letter-spacing: 2px;
                    color: GetColor(black);
                }

                .rating-wrapper {
                    
                    .rating {
                        width: 100px;
                        padding-bottom: 4px;
                    }
                }
            }
        }
    }

    .map {
        position: absolute;
        right: 10%;
        top: 59%;
        width: 30%;
        transform: translateY(-50%);
        border-radius: 6px;
        max-width: 500px;
    }
}

@media(max-width: $ipadPro11Size) {

    .google-listing {

        .listings {

            .listing {

                .details {

                    font-size: 14px;

                    .name {

                        font-size: 20px;
                    }
                }
            }
        }


        .map {

            right: 7%;
            width: 28%;
        }
    }
}

@media(max-width: $phoneSize) {

    .google-listing {

        .search-bar {

            .word-generator {
                
                width: 80%;
                height: 42px;
                font-size: 20px;
                margin-right: 10px;
            }

            .btn-search {

                line-height: 18px;
    
                a {
                    font-size: 18px !important;
                    font-weight: 600 !important;
                }
            }
        }

        .listings {

            .listing {

                .picture {

                    width: 130px;
                }

                .details {

                    margin-left: 10px;
                }
            }
        }

        .text {

            margin-top: 30px;
            margin-bottom: 30px;
            font-size: 18px;
            text-align: center;
            letter-spacing: normal;
            text-transform: initial;
        }

        .map {

            display: none;
        }
    }
}

@media(min-width: $desktopSize) {

    .google-listing {

        .map {

            right: 15%;
            width: 28%;
        }
    }
}

/*
    Portfolio
*/
.portfolio {

    position: relative;
    width: 100%;
    height: auto;
    margin-top: 50px;
    padding-top: 50px;
    padding-bottom: 200px;
    background-image: linear-gradient(to bottom, GetColor(white), white, white);

    .header {

        display: block;
        width: 100%;
        font-size: 48px;
        font-weight: 500;
        letter-spacing: 3px;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 20px;
    }
}

@media(max-width: $phoneSize) {

    .portfolio {

        .col-md-6 {

            margin-top: 20px;
            margin-bottom: 20px;

            img.w-50 {

                width: 80% !important;
            }
        }
    }
}

/*
    Alerts
*/
.amercan-alert {
    width: auto;
    height: auto;
    display: block;
    border-radius: 8px;
    font-size: 18px;
    line-height: 34px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 10px;
}

.amercan-alert.error {
    background-color: GetColor(red);
    color: GetColor(white);
}

.amercan-alert.success {
    background-color: GetColor(green);
    color: GetColor(white);
}

/*
    Services
*/
.services {

    position: relative;
    width: 100%;
    height: auto;

    .service-listings-desktop, .service-listings-mobile {
        position: relative;
        width: 100%;
        height: auto;
    }

    .header {

        font-size: 48px;
        font-weight: 500;
        letter-spacing: 3px;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 20px;
    }

    .header.mt {

        margin-top: 80px;
    }

    .text {

        font-size: 18px;

        a.btn {
            text-transform: uppercase;
            font-size: 16px;
            letter-spacing: 2px;
        }
    }

    .text.mb {

        margin-bottom: 100px;
    }

    .call-to-action {

        text-align: center;
        margin-top: 80px;
        margin-bottom: 80px;

        a {
            text-transform: uppercase;
            font-size: 32px;
            font-weight: 600;
            letter-spacing: 2px;
        }
    }
}

@media(min-width: $laptopSize) {

    .services {

        .service-listings-desktop {

            display: block;
        }

        .service-listings-mobile {

            display: none;
        }
    }
}

@media(max-width: $laptopSize) {

    .services {

        margin-bottom: 100px;

        .col-lg-12 {

            margin-bottom: 50px;
        }

        .service-listings-desktop {

            display: none;
        }

        .service-listings-mobile {

            display: block;
        }

        .header {

            font-size: 38px;
            font-weight: 500;
            letter-spacing: 2px;
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 20px;
        }

        a.btn {
            text-transform: uppercase;
            font-size: 20px;
            letter-spacing: 2px;
        }

        .call-to-action {

            text-align: center;
            margin-top: 80px;
            margin-bottom: 30px;
    
            a {
                text-transform: uppercase;
                font-size: 28px;
                font-weight: 600;
                letter-spacing: 2px;
            }
        }
    }
}

/*  
    Trust Pilot
*/
.trust-pilot {

    margin-top: 20px;
    margin-bottom: 20px;

    a {

        color: white;
        text-decoration: none;

        img {

            width: 125px;
        }
    }
}

/*
    Privacy Policy
*/
.privacy-policy {

    margin-top: 25px;
    margin-bottom: 70px;
}

/*
    Web Pricing
*/
.divider {

    width: 200px;
    height: 4px;
    background-color: GetColor(primary);
    border-radius: 2px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 50px;
}

.web-pricing {

    position: relative;
    margin-top: 40px;

    .header {

        font-size: 48px;
        font-weight: 500;
        letter-spacing: 3px;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 40px;
    }

    .disclaimer {

        display: inline-block;
        width: auto;
        margin-top: 20px;
        background-color: GetColor(pricing-grey-1);
        padding: 15px;
        border-radius: 10px;
        text-transform: uppercase;
        font-size: 16px;
        text-align: center;
        letter-spacing: 2px;
    }

    a {

        text-decoration: none;
    }

    a .pricing-option.professional {

        color: GetColor(white);

        .cap-bg {

            background-color: GetColor(pricing-grey-3);
        }

        .cap {

            background-color: GetColor(pricing-grey-3);
        }

        .option-features {

            color: GetColor(black);
        }
    }

    a .pricing-option.enterprise {

        color: GetColor(white);

        .cap-bg {

            background-color: GetColor(primary);
        }

        .cap {

            background-color: GetColor(primary);
        }

        .option-features {

            color: GetColor(black);
        }
    }

    a .pricing-option {

        position: relative;
        width: 100%;
        height: 500px;
        background-color: GetColor(pricing-grey-1);
        color: GetColor(primary);
        border-radius: 10px;
        overflow: hidden;

        .cap-bg {

            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 25%;
            background-color: GetColor(pricing-grey-2);
        }

        .cap {
            
            position: relative;
            width: 100%;
            height: auto;
            padding: 25px;
            background-color: GetColor(pricing-grey-2);
            //clip-path: polygon(100% 0, 100% 90%, 90% 100%, 10% 100%, 0 90%, 0 0);
            clip-path: inset(0% 0% 0% 0% round 45%);
            text-align: center;
            text-transform: uppercase;
            

            .name {

                font-size: 38px;
                line-height: 38px;
                letter-spacing: 2px;
                margin-bottom: 10px;
            }

            .name.sm {
                
                font-size: 32px;
            }

            .super-price {

                font-size: 14px;
                line-height: 12px;
                letter-spacing: normal;
            }

            .price {

                font-size: 64px;
                line-height: 62px;
                letter-spacing: 4px;
                font-weight: 700;
                margin-bottom: 15px;
            }

            .sub-price {

                font-size: 20px;
                line-height: 20px;
                letter-spacing: 1px;
                margin-bottom: 20px;
            }
        }


        .option-features {

            text-align: center;
            padding: 20px;

            ul {
                padding-left: 0px;
                list-style: none;

                li {

                    font-size: 18px;
                    letter-spacing: 2px;
                    margin-bottom: 10px;
                }
            }

            ul.condensed {

                li {

                    margin-bottom: 4px;
                }
            }
        }
    }
}

@media(max-width: $ipadSize) {

    .web-pricing {

        a .pricing-option {

            position: relative;
            width: 100%;
            height: 500px;
            background-color: GetColor(pricing-grey-1);
            color: GetColor(primary);
            border-radius: 10px;
            overflow: hidden;
    
            .cap-bg {
    
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 25%;
                background-color: GetColor(pricing-grey-2);
            }
    
            .cap {
                
                position: relative;
                width: 100%;
                height: auto;
                padding: 25px;
                background-color: GetColor(pricing-grey-2);
                //clip-path: polygon(100% 0, 100% 90%, 90% 100%, 10% 100%, 0 90%, 0 0);
                clip-path: inset(0% 0% 0% 0% round 45%);
                text-align: center;
                text-transform: uppercase;
                
    
                .name {

                    font-size: 30px;
                    line-height: 38px;
                    letter-spacing: 2px;
                    margin-bottom: 10px;
                }
    
                .name.sm {
                    
                    font-size: 26px;
                }
    
                .super-price {
    
                    font-size: 14px;
                    line-height: 12px;
                    letter-spacing: normal;
                }
    
                .price {
    
                    font-size: 64px;
                    line-height: 62px;
                    letter-spacing: 4px;
                    font-weight: 700;
                    margin-bottom: 15px;
                }
    
                .sub-price {
    
                    font-size: 20px;
                    line-height: 20px;
                    letter-spacing: 1px;
                    margin-bottom: 20px;
                }
            }
        }
    }
}

/*
    SEO Pricing
*/
.seo-pricing {

    .header {

        font-size: 48px;
        font-weight: 500;
        letter-spacing: 3px;
        text-transform: uppercase;
        text-align: center;
    }

    .sub-header {

        font-size: 22px;
        font-weight: 500;
        letter-spacing: normal;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 40px;
    }

    .pricing-option {

        width: 80%;
        margin-bottom: 30px;
        padding: 15px;
        border-radius: 10px;
        background-color: GetColor(pricing-grey-1);
        font-size: 34px;
        font-weight: 500;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        text-transform: uppercase;
    }

    .pricing-option.total {

        background-color: GetColor(primary);
        color: GetColor(white);
    }
}

/*
    Social Media Pricing
*/
.social-media-pricing {

    .header {

        font-size: 48px;
        font-weight: 500;
        letter-spacing: 3px;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 30px;
    }

    .providers, .platform, .total {

        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 20px;
        margin-bottom: 30px;

        .monogram,.bubble,.header {

            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
        }

        .header {

            font-size: 34px;
            line-height: 40px;
            font-weight: 500;
            letter-spacing: 2px;
            text-transform: uppercase;
            margin-bottom: 0;
        }

        .bubble {

            width: 100%;
            height: auto;
            border-radius: 10px;
            background-color: GetColor(pricing-grey-1);
            color: GetColor(primary);

            .price {

                display: block;
                text-align: center;
                font-size: 52px;
                line-height: 54px;
                font-weight: bold;
                text-transform: uppercase;
                letter-spacing: 2px;
            }

            .sub-price {

                display: block;
                text-align: center;
                font-size: 20px;
                line-height: 22px;
                letter-spacing: 1px;
                text-transform: uppercase;
            }

            .source {

                font-size: 18px;
                line-height: 28px;
                text-transform: uppercase;
                letter-spacing: 1px;
            }
        }

        .bubble.ami {

            background-color: GetColor(primary);
            color: GetColor(white);
        }

        .bubble.link {

            background-color: GetColor(pricing-grey-1);
            color: GetColor(primary);
            transition: 0.5s ease all;

            a {
                text-decoration: none;
                color: GetColor(primary);
            }
        }

        .bubble.link:hover {

            background-color: GetColor(primary);

            a {
                color: GetColor(white);
            }
        }
    }

    .total-header {

        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;

        .header {

            grid-column: 2 / 5;
        }
    }
}

@media(max-width: $ipadSize) {

    .social-media-pricing {
        .providers, .platform, .total {

            .header {

                font-size: 34px;
                line-height: 40px;
                font-weight: 500;
                letter-spacing: 2px;
                text-transform: uppercase;
                margin-bottom: 0;
            }

            .bubble {

                .price {

                    display: block;
                    text-align: center;
                    font-size: 38px;
                    line-height: 40px;
                    font-weight: bold;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                }

                .sub-price {

                    display: block;
                    text-align: center;
                    font-size: 18px;
                    line-height: 20px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                }

                .source {

                    font-size: 16px;
                    line-height: 28px;
                    letter-spacing: normal;
                }
            }
        }
    }
}

@media(max-width: $ipadPro11Size) {

    .social-media-pricing {
        .providers, .platform, .total {

            .header {

                font-size: 34px;
                line-height: 40px;
                font-weight: 500;
                letter-spacing: 2px;
                text-transform: uppercase;
                margin-bottom: 0;
            }

            .bubble {

                .price {

                    display: block;
                    text-align: center;
                    font-size: 32px;
                    line-height: 40px;
                    font-weight: bold;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                }

                .source {

                    font-size: 14px;
                    line-height: 28px;
                    letter-spacing: normal;
                }
            }
        }
    }
}

@media(max-width: $phoneSize) {

    .social-media-pricing {
        .providers, .platform, .total {

            grid-gap: 12px;
            margin-bottom: 20px;

            .header {

                font-size: 18px;
                line-height: 26px;
                font-weight: 500;
                letter-spacing: normal;
                text-transform: uppercase;
                margin-bottom: 0;
            }

            .monogram {
                .w-50 {
                    width: 100% !important;
                }

                .w-75 {
                    width: 100% !important;
                }
            }

            .bubble {

                .price {

                    display: block;
                    text-align: center;
                    font-size: 18px;
                    line-height: 28px;
                    font-weight: bold;
                    text-transform: uppercase;
                    letter-spacing: normal
                }

                .sub-price {

                    display: block;
                    text-align: center;
                    font-size: 12px;
                    line-height: 18px;
                    letter-spacing: normal;
                    text-transform: uppercase;
                }

                .source {

                    display: none;
                    font-size: 8px;
                    line-height: 18px;
                    letter-spacing: normal;
                }
            }
        }

        .total-header {

            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
    
            .header {
    
                grid-column: 1 / 5;
            }
        }
    }
}

@media(max-width: $smallPhoneSize) {

    .social-media-pricing {
        .providers, .platform, .total {

            grid-gap: 10px;
            margin-bottom: 15px;

            .header {

                font-size: 16px;
                line-height: 26px;
                font-weight: 500;
                letter-spacing: normal;
                text-transform: uppercase;
                margin-bottom: 0;
            }

            .monogram {
                .w-50 {
                    width: 100% !important;
                }

                .w-75 {
                    width: 100% !important;
                }
            }

            .bubble {

                .price {

                    display: block;
                    text-align: center;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: bold;
                    text-transform: uppercase;
                    letter-spacing: normal
                }

                .sub-price {

                    display: block;
                    text-align: center;
                    font-size: 10px;
                    line-height: 14px;
                    letter-spacing: normal;
                    text-transform: uppercase;
                }

                .source {

                    display: none;
                    font-size: 6px;
                    line-height: 18px;
                    letter-spacing: normal;
                }
            }
        }

        .total-header {

            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
    
            .header {
    
                grid-column: 1 / 5;
            }
        }
    }
}

/*
    Ad Campaigns Pricing
*/
.ad-campaign-pricing {

    position: relative;
    margin-bottom: 100px;

    .desktop {

        display: block;
    }

    .mobile {
        
        display: none;
    }

    .header {

        font-size: 48px;
        font-weight: 500;
        letter-spacing: 3px;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 30px;
    }

    .text {

        font-size: 30px;
        text-align: center;
    }

    a {
        display: inline;
        width: auto;
        color: GetColor(white);
        text-decoration: none;
        text-transform: uppercase;
        text-align: center;
    }

    .bubble {

        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        height: auto;
    }

    .bubble.cost {

        margin-top: 30px;

        div {
            border-radius: 10px;
            padding: 20px 30px 20px 30px;
            background-color: GetColor(primary);
            color: GetColor(white);

            .price {
                display: block;
                font-weight: bold;
                font-size: 42px;
                line-height: 50px;
                letter-spacing: 3px;
            }

            .sub-price {

                display: block;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: 1px;
            }
        }
    }
}

@media(max-width: calc($ipadSize - 2px)) {

    .ad-campaign-pricing {

        .desktop {
            display: none;
        }

        .mobile {
            display: block;
        }
    
        .text {
    
            font-size: 28px;
            text-align: center;
        }
    
        .bubble.cost {
    
            div {
                .price {
                    display: block;
                    font-weight: bold;
                    font-size: 32px;
                    line-height: 50px;
                    letter-spacing: 3px;
                }
    
                .sub-price {
    
                    display: block;
                    font-size: 18px;
                    line-height: 24px;
                    letter-spacing: 1px;
                }
            }
        }
    }
}

@media(max-width: $phoneSize) {

    .ad-campaign-pricing {

        .desktop {
            display: none;
        }

        .mobile {
            display: block;
        }
    
        .text {
    
            font-size: 22px;
            text-align: center;
        }
    
        .bubble.cost {
    
            div {
                .price {
                    display: block;
                    font-weight: bold;
                    font-size: 30px;
                    line-height: 46px;
                    letter-spacing: 3px;
                }
    
                .sub-price {
    
                    display: block;
                    font-size: 18px;
                    line-height: 24px;
                    letter-spacing: 1px;
                }
            }
        }
    }
}

/*
    Contact
*/
.contact {

    .header {

        text-transform: uppercase;
    }
}

.discord-regular {

    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
    background-color: GetColor(primary);
    padding: 20px;
    opacity: 1;
    transition: 0.5s ease opacity;

    .discord {

        width: 150px;
    }
}

.discord-regular:hover {

    opacity: 0.6;
}

/*  
    Footer
*/
footer {

    position: absolute;
    bottom: -200px;
    left: 0;
    width: 100%;
    height: auto;
    background-color: GetColor(primary);
    color: GetColor(white);

    .branding {

        position: absolute;
        width: 300px;
        right: 0%;
        top: 0%;
    }

    .discord {
        position: relative;
        width: 150px;

        opacity: 1;
        transition: 0.3s ease all;
    }

    .discord:hover {

        opacity: 0.45;
    }

    .bbb {

        position: relative;
        width: 150px;
    }

    a.nav-link.p-0.text-muted {

        transition: 0.5s ease all;
    }

    a.nav-link.p-0.text-muted:hover {

        color: GetColor(white) !important;
    }
}

@media(max-width: $phoneSize) {

    footer {

        bottom: -300px;

        .discord {
            position: relative;
            width: 125px;
    
            opacity: 1;
            transition: 0.3s ease all;
        }
    }
}

@media(min-width: $ipadPro11Size) {

    footer {

        .discord {

            width: 200px;
        }
    }
}

/*
    404
*/
.error-404 {

    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    width: 100vw;
    min-height: 80vh;
    background: radial-gradient(white, GetColor(white));

    .animation {

        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 75vw;
        height: 35vw;
    
        img {

            width: 100%;
        }

        h1 {

            margin-top: -5vw;
        }
    }
}

/* 
    Social Media Links
*/
.social-media-links {

    position: absolute;
    right: 20px;
    top: 50px;
    display: flex;
    justify-content: end;
    align-items: center;

    a {

        margin-right: 30px;
        text-decoration: none;

        img {

            width: 60px;
        }
    }
}

@media(max-width: $phoneSize) {

    .social-media-links {

        margin-top: 30px;
        margin-bottom: 30px;
        display: flex;
        width: 100%;
        height: auto;
    
        a {
    
            img {
    
                width: 40px;
            }
        }
    }
}

