/*
    Amercan Marketing Inc.
    SCSS Variables
    2022-02-03
*/

$masterPallete : (

    primary: rgba(13, 13, 18, 1),
    white: rgba(251, 252, 253, 1),
    black: rgba(5, 5, 5, 1),
    grey: rgba(110, 110, 110, 1),
    transparent: rgba(0, 0, 0, 0),

    pricing-grey-1: rgba(231, 232, 233, 1),
    pricing-grey-2: rgba(211, 212, 213, 1),
    pricing-grey-3: rgba(101, 102, 103, 1),

    sales-red: rgba(235, 53, 6),

    red: rgb(153, 16, 16),
    green: rgb(16, 153, 16),
);

@function GetColor($name) 
{
    @return map-get($masterPallete, $name);
}

/*
    Bootstrap Themeing
*/

$theme-colors: (
    primary: GetColor(primary),
    secondary: GetColor(white),

    success: GetColor(sales-red),
);

/*
    Device Sizes
*/

$ipadSize: 1025px;
$ipadPro11Size: 840px;
$phoneSize: 767px;
$smallPhoneSize: 410px;
$laptopSize: 1439px;
$desktopSize: 1800px;
